import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import PostCardsList from "../components/widget/PostCardsList"
import { NewsletterBanner } from "../components/NewsletterBanner"

const TagTemplate = ({ data, pageContext, location }) => {
  const { tag, slug } = pageContext
  const { posts, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"} tagged with #${tag}`
  const siteTitle = data.site.siteMetadata?.title || `Manu Sobles `;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`Posts tagged with #${tag}`}
        pathName={slug}
        article={false}
      />

      <div>
        <h1 className="mt-8 text-4xl">{tagHeader}</h1>
        <div className="block mb-8">
          <Link to="/tags" className="button">All tags</Link>
        </div>
        <PostCardsList posts={posts} />
      </div>
      
      <NewsletterBanner />
    </Layout>
  )
}

TagTemplate.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      posts: PropTypes.arrayOf(
        PropTypes.shape({
          excerpt: PropTypes.string.isRequired,
          timeToRead: PropTypes.number.isRequired,
          fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }),
          frontmatter: PropTypes.shape({
            tags: PropTypes.array,
            title: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
            imgPath: PropTypes.string.isRequired,
            imgAlt: PropTypes.string.isRequired,
            template: PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
  }),
}

export default TagTemplate

export const pageQuery = graphql`
  query Tags(
    $tag: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount      
      posts: nodes {
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          tags
          title
          date
          excerpt
          imgPath: img_path
          imgAlt: img_alt
          template
        }
      }
    }
  }
`
